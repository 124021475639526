import { Chart, registerables } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"

Chart.register(...registerables)
Chart.register(ChartDataLabels)

function drawYAxis(chart, name) {
  const yAxis = $($(`#${name}_y_axis`))
  const yAxisCanvas = yAxis[0]
  const ctx = yAxisCanvas.getContext("2d")

  // Scale pixel ratio to keep the quality of the canvas
  const pixelRatio = window.devicePixelRatio || 1
  const canvasWidth = yAxisCanvas.width
  const canvasHeight = chart.height
  yAxisCanvas.width = canvasWidth * pixelRatio
  yAxisCanvas.height = canvasHeight * pixelRatio
  ctx.scale(pixelRatio, pixelRatio)

  ctx.clearRect(0, 0, canvasWidth, canvasHeight)

  const yScale = chart.scales["y"]
  ctx.font = `${yScale.options.ticks.font.size}px ${yScale.options.ticks.font.family}`
  ctx.fillStyle = yScale.options.ticks.color
  ctx.strokeStyle = yScale.options.grid.color
  ctx.lineWidth = 1

  // Calculate longest tick label on y-axis
  const longestLabelWidth = Math.max(
    ...yScale.ticks.map((tick) => ctx.measureText(tick.label).width)
  )

  // Position of y-axis bar on its canvas
  const yAxisX = 299

  // Set width, height, position for y-axis canvas
  yAxis.css({
    width: `${canvasWidth}px`,
    height: `${canvasHeight}px`,
    left: `${-yAxisX + longestLabelWidth + 18}px`,
  })

  // Draw y-axis bar
  ctx.beginPath()
  ctx.moveTo(yAxisX, chart.options.layout.padding.top - 0.5)
  // Adjust y-axis height based on certain circumstances
  ctx.lineTo(
    yAxisX,
    name !== "number_of_listings_chart" ? canvasHeight - 18 : 288
  )

  // Set color y-axis bar
  ctx.strokeStyle = "#D3D3D3"
  ctx.stroke()

  // Draw tick labels on y-axis
  const ticks = yScale.ticks
  ticks.forEach((tick, index) => {
    const tickPosition = yScale.getPixelForTick(index)

    ctx.beginPath()
    ctx.moveTo(yAxisX - 8, tickPosition)
    ctx.lineTo(yAxisX, tickPosition)
    ctx.stroke()

    ctx.textAlign = "right"
    ctx.fillStyle = yScale.options.ticks.color
    ctx.fillText(tick.label, yAxisX - 12, tickPosition + 3)
  })
}

function barChartConfig(data, name, isSmartPhone, configs = {}) {
  const maxValue = Math.max(...data.datasets[0].data)
  const newMaxValue = maxValue <= 90 && maxValue >= 10 ? maxValue + 5 : maxValue
  // Max value on y-axis
  const ySuggestedMax = name === "export_achievement_rate_chart" ? newMaxValue : maxValue * 1.1
  const stepSize = name === "export_achievement_rate_chart" ? 20 : data.datasets[0].data.every((size) => size <= 10) ? 1 : 0
  // Layout padding top
  let paddingTop = isSmartPhone ? 20 : 25
  // Unit on y-axis
  const unit = name === "export_achievement_rate_chart" ? "%" : " Units"
  return {
    type: "bar",
    data: {
      labels: data.labels,
      datasets: [
        {
          data: data.datasets[0].data,
          backgroundColor: "#FFC3CB",
          datalabels: {
            color: "#E43A51",
            anchor: "end",
            align: "top",
            rotation: function (context) {
              const value = context.dataset.data[context.dataIndex]
              return value >= 10000 ? -30 : 0
            },
            formatter: function (value) {
              if (value >= 10000 && !isSmartPhone) {
                return `${value} `
              }
              return value
            },
          },
          barThickness: configs.barThickness,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          top: paddingTop
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: isSmartPhone ? 10 : 12,
            },
            labelOffset: configs.labelOffset,
            callback: function (value, index, ticks) {
              if (configs.xCallBack) return configs.xCallBack(value, index)
              return data?.labels[index]
            },
          },
        },
        y: {
          ticks: {
            stepSize: stepSize,
            callback: function (value, index, ticks) {
              return `${value}${value !== 0 ? unit: ""}`
            },
            font: {
              size: isSmartPhone ? 10 : 12,
            },
          },
          grid: {
            display: true,
          },
          suggestedMax: ySuggestedMax
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          font: {
            size: isSmartPhone ? 10 : 12,
          },
        },
      },
    },
  }
}

class AnalysisChart {
  constructor(isSmartPhone) {
    this.isSmartPhone = isSmartPhone
  }

  fetchBarChart(selector, chartId, barThickness, labelOffset = 0) {
    $(document).ready(() => {
      const chartElement = $(selector)
      if (!chartElement.length) return
      const context = $(`#${chartId}`)[0].getContext("2d")
      $.ajax({
        type: "GET",
        url: $(selector).attr("data-url"),
        success: (response) => {
          const { last_updated, data } = response
          const xCallBack =
            chartId === "number_of_listings_chart"
              ? (value, index) =>
                  [0, 5, 10, 15, 20, 25].includes(index)
                    ? data.labels[index]
                    : ""
              : null

          $(selector).find(".js-last-updated-date").html(last_updated)
          const chart = new Chart(
            context,
            barChartConfig(data, chartId, this.isSmartPhone, {
              barThickness,
              labelOffset,
              xCallBack,
            })
          )
          if (this.isSmartPhone){
            drawYAxis(chart, chartId)
            $(selector).find(".chart__content-main").scrollLeft($(`#${chartId}`)[0].scrollWidth)
          }
        },
        error: () => {
          $(selector).hide()
        },
      })
    })
  }

  fetchExportAchievementRateChart() {
    this.fetchBarChart(
      ".chart.export_achievement_rate",
      "export_achievement_rate_chart",
      this.isSmartPhone ? 30 : 50
    )
  }

  fetchNumberOfListingsChart() {
    this.fetchBarChart(
      ".chart.number_of_listings",
      "number_of_listings_chart",
      16,
      29
    )
  }
}

export default AnalysisChart
