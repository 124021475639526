import { Controller } from 'stimulus'
import { ajax } from '@rails/ujs'
import favorite_action from '../../components/favotite_action'
import { estPriceAlerts, loadOptions, execEstTotalPrice,
         displayTotalPrice, changeTradetpots, pickPriceFormData, checkCountryPreshipRequired } from '../shared/est_price_module'
import fetch_frame_user_reviews from '../shared/user_review_frame'
import load_target_view from '../shared/load_view'
import { isUserLogin } from '../shared/users_api';
import { offerCarDetailParams } from '../shared/offer_params'
import { getDataURLSearchParams } from '../shared/url_search_param'
import { getInquiryCars } from '../shared/inquiry_car';
import passAjaxInvalidAuthenticityToken from '../shared/authenticity_hanlder'
import setDefaultContactSeller from '../pc/page_list'
import loadModalAssist from '../shared/modal_assist'
import AnalysisChart from '../shared/analysis_chart'

export default class extends Controller {
  static targets = [
    'breadcrumbsArea', 'searchHeaderArea', 'searchInfoArea',
    'searchInfoAreaWrap', 'modalSelectDest', 'shadeForModalSelectDest',
    'tooltipEstModal', 'countrySelectOptions', 'portSelectOptions', 'btnCalculate',
    'checkboxInsurance', 'checkboxShipping', 'checkboxInspection', 'estPriceForm',
    'incoterm', 'nearestPort', 'selectionNearestPort', 'titleSelectDest',
    'selectionNearestPortOnTop', 'incotermOnHeader', 'nearestPortOnHeader',
    'modalAlert', 'symbolAsterisk', 'sameModelArea', 'popularModels',
    'popularCars', 'sameCars', 'mainList', 'popularModelList', 'sameModelList',
    'userReviewsArea', 'descriptionArea', 'recommendMakeModelArea',
    'recommendModelBodyType', 'internalLinksArea', 'priceListArea'
  ]

  static values = {
    carIds: Array
  }

  connect() {
    document.body.addEventListener('click', function() {
      let popovers = document.getElementsByClassName('tcv-popover');
      let tooltipEstModals = document.getElementsByClassName('appearance checkbox-help-tooltip');

      for (var i = 0; i < popovers.length; i++) {
        popovers[i].style.display = 'none';
      }

      for (var i = 0; i < tooltipEstModals.length; i++) {
        tooltipEstModals[i].classList.remove('appearance')
      }
    });
    this.loadPortInfo(this.countrySelectOptionsTarget, true)

    this.scrollListener = this.showSearchInfoWhenScrolling.bind(this);
    window.addEventListener('scroll', this.scrollListener)
    favorite_action(this.mainListTarget, true)
    this.handleSendButton(this.mainListTarget)
    window.addEventListener('load', () => {
      setTimeout(() => {
        this.loadPortInfo(this.countrySelectOptionsTarget, true)
        this.async_model_fetching()
        this.showNearestPort()
        if (this.hasUserReviewsAreaTarget) { fetch_frame_user_reviews(this.userReviewsAreaTarget) }
        load_target_view(this, 'descriptionArea')
        load_target_view(this, 'recommendMakeModelArea')
        load_target_view(this, 'recommendModelBodyType')
        load_target_view(this, 'internalLinksArea')
        load_target_view(this, 'priceListArea')
      }, 100)
    })

    this.fetchModals();
    loadModalAssist()
    this.loadAnalysisChart()
  }

  showHelperMessage(e) {
    e.stopPropagation();
    const target  = e.currentTarget
    const msgType = target.dataset.msgType
    const wrapper = target.querySelector('.ico.icon-help');
    this.closePopovers(e);

    if (wrapper) {
      wrapper.innerHTML = this.templateMessages(msgType)
    }
  }

  closePopovers(e) {
    e.stopPropagation();
    let popovers = document.getElementsByClassName('tcv-popover');

    for (var i = 0; i < popovers.length; i++) {
      popovers[i].remove()
    }
  }

  templateMessages(type) {
    const msg = {
      estimated_total_price: "Search for the Estimated Total Price of a car based on your shipping destination port and other preferences.",
      feedback_total: "<b>Seller ratings</b><br /><br />The stars show the rating of the seller based on a one-to-five star scale. It can provide a complete picture of a seller's performance."
    }

    return `<div class="tcv-popover" onclick="event.stopPropagation();" style="display: block;">
      <div class="tcv-popover__wrap">
        ${msg[type]}
        <button class="ico icon-close" data-action="click->search#closePopovers"></button>
      </div>
    </div>`
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollListener)
  }

  showSearchInfoWhenScrolling() {
    let breadcrumbsArea = this.breadcrumbsAreaTarget
    let scrollTop = window.pageYOffset
    let headerArea = document.querySelector('.header-area')
    let searchHeaderArea = this.searchHeaderAreaTarget
    let searchInfoArea = this.searchInfoAreaTarget
    let searchInfoAreaWrap = this.searchInfoAreaWrapTarget

    const breadcrumbsAreaHeight = breadcrumbsArea.clientHeight
    const headerAreaHeight = headerArea.clientHeight
    const searchInfoAreaWrapHeight = searchInfoAreaWrap.clientHeight
    const topBannerHeight = 90

    const scrollTopToShowSearchHeaderArea = breadcrumbsAreaHeight + headerAreaHeight
    const scrollTopToShowSearchInfoArea = scrollTopToShowSearchHeaderArea + searchInfoAreaWrapHeight + topBannerHeight

    if (scrollTop >= scrollTopToShowSearchHeaderArea) {
      searchHeaderArea.classList.add('header__area__visible')
    } else {
      searchHeaderArea.classList.remove('header__area__visible')
    }

    if (scrollTop >= scrollTopToShowSearchInfoArea) {
      searchInfoArea.classList.add('search__info__area__visible')
    } else {
      searchInfoArea.classList.remove('search__info__area__visible')
    }
  }

  scrollToTopOfThePage(e) {
    e.preventDefault()
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  changeListView(event) {
    event.preventDefault()
    let wrappers = $('.vehicle__list-area')
    let listView = event.currentTarget.dataset.listView

    if (listView == 'line') {
      wrappers.removeClass('vehicle--grid')
    } else {
      wrappers.addClass('vehicle--grid')
    }
  }

  redirectToDetailPath(e) {
    const currentTarget = e.currentTarget
    const url = currentTarget.querySelector('.car-item__pic-area a').href
    window.open(url, '_blank')
  }

  showModalEstPrice(e) {
    e.stopPropagation()
    this.modalSelectDestTarget.classList.add('appearance')
    this.shadeForModalSelectDestTarget.classList.add('appearance')
  }

  closeModalEstPrice() {
    this.modalSelectDestTarget.classList.remove('appearance')
    this.shadeForModalSelectDestTarget.classList.remove('appearance')
  }

  showTooltip(e) {
    e.stopPropagation()
    this.tooltipEstModalTargets.forEach(target => target.classList.remove('appearance'))
    e.currentTarget.nextSibling.classList.add('appearance')
  }

  closeTooltip(e) {
    e.currentTarget.classList.remove('appearance')
  }

  loadPortInfo(e, init = false) {
    const self = this;
    let data
    const ettSelectCountry = $('#ett_select_country').val()
    const currentTarget = e.target === undefined ? e : e.target
    if (init && ettSelectCountry != 'undefined') {
      data = { country_number: ettSelectCountry }
      this.countrySelectOptionsTarget.value = ettSelectCountry
    } else {
      data = { country_number: currentTarget.value }
      $('#ett_select_country').val(currentTarget.value)
    }
    const selectedValue = $('#ett_select_port').val() != 'undefined' ? $('#ett_select_port').val() : this.portSelectOptionsTarget.dataset.portSelected

    ajax({
      url: '/ajax_v2/estimated_total_prices/nearest_ports',
      type: 'get',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      success: function (response) {
        self.portSelectOptionsTarget.innerHTML = ''
        self.portSelectOptionsTarget.add(new Option('Please Select', 0))
        self.btnCalculateTarget.disabled = true

        if (response.ports === undefined || response.ports.length == 0) {
          self.portSelectOptionsTarget.disabled = true
        } else {
          self.portSelectOptionsTarget.disabled = false
          response.ports.forEach(port =>{
            let option = new Option(port[1], port[0]);
            self.portSelectOptionsTarget.add(option)
          })

          const selectedValueIsCollect = response.ports.find((portChild) => parseInt(selectedValue) == portChild[0])

          if (selectedValueIsCollect && init) {
            let optionSelected = self.portSelectOptionsTarget.querySelector(`option[value="${selectedValue}"]`)

            optionSelected.selected = true
            self.btnCalculateTarget.disabled = false
            document.querySelector('input#checkbox_shipping').disabled = false
            document.querySelector('input#checkbox_insurance').disabled = false
            if (checkCountryPreshipRequired(self.countrySelectOptionsTarget.value)) {
              document.querySelector('input#checkbox_inspection').checked = true
              document.querySelector('input#checkbox_inspection').disabled = true
            } else {
              document.querySelector('input#checkbox_inspection').disabled = false
            }
          } else {
            document.querySelector('input#checkbox_shipping').disabled = true
            document.querySelector('input#checkbox_insurance').disabled = true
            document.querySelector('input#checkbox_inspection').disabled = true
            $('#ett_select_port').val('0')
          }
        }
      }
    })

    estPriceAlerts(this.modalAlertTargets, this.symbolAsteriskTarget, currentTarget.value)
    loadOptions(currentTarget.value, self.checkboxShippingTarget, self.checkboxInsuranceTarget, self.checkboxInspectionTarget);

    if (init) {
      if ($('#ett_tradetpots').val() == 'undefined') return

      const { shipping, insurance, inspection } = JSON.parse($('#ett_tradetpots').val())
      document.querySelector('input#checkbox_shipping').checked = shipping
      document.querySelector('input#checkbox_insurance').checked = insurance
      document.querySelector('input#checkbox_inspection').checked = inspection
    } else {
      document.querySelector('input#checkbox_shipping').checked = false
      document.querySelector('input#checkbox_insurance').checked = false
      document.querySelector('input#checkbox_inspection').checked = true
      this.handleChangeTradetpots()
    }
  }

  handleChangeTradetpots() {
    changeTradetpots('ett_tradetpots', 'checkbox_shipping', 'checkbox_insurance', 'checkbox_inspection')
  }

  fetchModals() {
    const elTarget = $(".modal-list");
    $.ajax({
      url: '/ajax_v2/search_fetch_modals',
      type: 'GET',
      success: function(response) {
        elTarget.append(response.modal_signin + response.modal_signup + response.modal_contact_seller);
        setDefaultContactSeller();

        $(".tcv-modal .icon-close").on("click", function() {
          $(".tcv-modal").modal("hide");
        })
      }
    })
  }

  calculateEstTotalPrice(_context, init = false) {
    if (!init) {
      this.saveCookieEstPrice()
    } else {
      this.implementEstimateTotalPrice(true)
      this.closeModalEstPrice()
    }
  }

  implementEstimateTotalPrice(init = false) {
    const priceFormData = pickPriceFormData(init, 'ett_country_port', this.estPriceFormData())

    execEstTotalPrice(priceFormData, this.carIdsValue, true, (response) => {
      if (Object.keys(response.total_price_items).length == 0) return;

      response.total_price_items.forEach(item => {
        const carItems = document.querySelectorAll(`.vehicle__car-item[data-car-id='${item.itemId}']`)
        if (carItems.length === 0) return;

        carItems.forEach(carItem => {
          const carItemWrap = carItem.querySelector('.car-item__info-area')
          carItemWrap.classList.remove('not_estimate')
          carItemWrap.classList.add('estimated')
          carItem.querySelectorAll('.group__estimated span.total__price').forEach(el => el.innerText = displayTotalPrice(item))
          carItem.querySelectorAll('.group__estimated .icon-cached').forEach(el => el.classList.remove('rotate'))
        })
      })
    })
  }

  showNearestPort() {
    if ($('#ett_incoterm_port').val() == 'undefined') return

    const { icoterm, port } = JSON.parse($('#ett_incoterm_port').val())
    this.incotermTarget.innerText = icoterm
    this.nearestPortTarget.innerText = port

    this.modalSelectDestTarget.classList.remove('appearance')
    this.incotermTarget.innerText = icoterm
    this.incotermOnHeaderTarget.innerText = icoterm
    this.selectionNearestPortTarget.classList.remove('d-none')
    this.selectionNearestPortOnTopTarget.classList.remove('d-none')
    this.nearestPortTarget.innerText = port
    this.nearestPortOnHeaderTarget.innerText = port
    this.titleSelectDestTarget.innerText = 'Change destination'
  }

  estPriceFormData() {
    const form = this.estPriceFormTarget
    return {
      country_number: form.querySelector('select#select_your_country').value,
      port_id: form.querySelector('select#select_your_port').value || form.querySelector('select#select_your_port').dataset.portSelected,
      shipping: !this.checkboxShippingTarget.classList.contains('d-none') && !form.querySelector('input#checkbox_shipping').disabled
                && form.querySelector('input#checkbox_shipping').checked ? 1 : 0,
      insurance: !this.checkboxInsuranceTarget.classList.contains('d-none') && !form.querySelector('input#checkbox_insurance').disabled
                 && form.querySelector('input#checkbox_insurance').checked ? 1 : 0,
      inspection: checkCountryPreshipRequired(this.countrySelectOptionsTarget.value) || !this.checkboxInspectionTarget.classList.contains('d-none')
                  && !form.querySelector('input#checkbox_inspection').disabled
                  && form.querySelector('input#checkbox_inspection').checked ? 1 : 0,
      show_shipping: this.checkboxShippingTarget.classList.contains('d-none') ? 0 : 1
    }
  }

  saveCookieEstPrice() {
    const self = this;
    let form = this.estPriceFormTarget
    let data = this.estPriceFormData()
    ajax({
      url: '/ajax_v2/estimated_total_prices/cookies',
      type: 'put',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      success: (response) => {
        self.modalSelectDestTarget.classList.remove('appearance')
        self.incotermTarget.innerText = response.incoterms
        self.incotermOnHeaderTarget.innerText = response.incoterms
        self.selectionNearestPortTarget.classList.remove('d-none')
        self.selectionNearestPortOnTopTarget.classList.remove('d-none')
        let destination = form.querySelector('select#select_your_port').selectedOptions[0].innerText
        self.nearestPortTarget.innerText = destination
        self.nearestPortOnHeaderTarget.innerText = destination
        self.titleSelectDestTarget.innerText = 'Change destination'
        const incotermPort = { icoterm: response.incoterms, port: destination }
        $('#ett_incoterm_port').val(JSON.stringify(incotermPort))
        self.closeModalEstPrice()
        this.implementEstimateTotalPrice()
        let option_total_price = document.querySelector('#ptid-vehicle-sort option[value="60"]')
        if (response.current_destination === null || Object.keys(response.current_destination).length == 0) {
          if (option_total_price == null) return self.sortByCondition(null, response.sort_condition, false)

          if ([60, 61].includes(parseInt(response.sort_condition))) {
            self.sortByCondition(null, 1, true)
          } else {
            self.sortByCondition(null, response.sort_condition, false)
          }

          option_total_price.remove()
          document.querySelector('#ptid-vehicle-sort option[value="61"]').remove()
          document.querySelector('#sort_condition option[value="60"]').remove()
          document.querySelector('#sort_condition option[value="61"]').remove()
          document.querySelectorAll('.groub__btn-sort').forEach((el) => el.classList.remove('active'))
          document.querySelectorAll('.groub__btn-sort .btn__sort--outline.active').forEach((el) => el.classList.remove('active'))
        } else {
          if (option_total_price) return;

          const sort_options = [
            ['Total Price - High to Low', 61],
            ['Total Price - Low to High', 60]
          ]
          sort_options.forEach((option) => {
            document.getElementById('ptid-vehicle-sort').add(new Option(option[0], option[1]), 2)
            document.getElementById('sort_condition').add(new Option(option[0], option[1]), 2)
          })
          document.querySelectorAll('.groub__btn-sort').forEach((el) => el.classList.add('active'))
        }
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }

  toggleCalculateBtn(e) {
    $('#ett_select_port').val(e.currentTarget.value)
    if (e.currentTarget.value == 0) {
      document.querySelector('input#checkbox_shipping').disabled = true
      document.querySelector('input#checkbox_insurance').disabled = true
      document.querySelector('input#checkbox_inspection').disabled = true
      this.btnCalculateTarget.disabled = true
    } else {
      document.querySelector('input#checkbox_shipping').disabled = false
      document.querySelector('input#checkbox_insurance').disabled = false
      document.querySelector('input#checkbox_inspection').disabled = checkCountryPreshipRequired(this.countrySelectOptionsTarget.value)
      this.btnCalculateTarget.disabled = false
    }
  }

  sortByCondition(e, sortId, reloadPage = true) {
    let data = sortId ? { ptid: sortId } : { ptid: e.currentTarget.value }

    ajax({
      url: '/ajax_v2/change_sort_condition',
      type: 'post',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      success: function () {
        if (!reloadPage) return;

        var url = new URL(window.location.href);
        url.searchParams.delete("pn")
        window.location = url.href
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }

  selectPerPage(e, reloadPage = true) {
    let data = { per: e.target.value }

    ajax({
      url: '/ajax_v2/update_per_page',
      type: 'post',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      success: function () {
        if (!reloadPage) return;

        var url = new URL(window.location.href);
        url.searchParams.delete('pn')
        window.location = url.href
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }

  sortTotalPriceLowToHigh() {
    this.sortByCondition(null, 60, true)
  }

  sortFobPriceLowToHigh() {
    this.sortByCondition(null, 1, true)
  }

  async async_model_fetching() {
    document.querySelectorAll('.group__estimated .ico.icon-cached').forEach(icon => icon.classList.add('rotate'))
    await this.sameModalRequestAjax()
    await this.load_popular_models()

    getInquiryCars(this.carIdsValue)
    this.getFavoriteCars(this.carIdsValue)
    this.calculateEstTotalPrice(this, true)
  }

  async sameModalRequestAjax() {
    if (this.hasSameModelAreaTarget) {
      const target = this.sameModelAreaTarget
      const url = target.dataset.url
      const self = this

      await $.ajax({
        url: url,
        type: 'GET',
        success: function (response) {
          if (response === undefined) return

          target.outerHTML = response
        },
        complete: function () {
          if (self.hasSameModelListTarget) {
            favorite_action(self.sameModelListTarget)
            const car_ids = JSON.parse(self.sameCarsTarget.dataset.carIds)
            self.carIdsValue = self.carIdsValue.concat(car_ids)
            self.handleSendButton(self.sameModelListTarget)
          }
        }
      })
    }
  }

  async load_popular_models() {
    if (this.hasPopularModelsTarget) {
      const popularModels = this.popularModelsTarget
      const url = popularModels.dataset.url
      const self = this

      await $.ajax({
        url: url,
        type: 'GET',
        success: function (response) {
          if (response === undefined) return

          popularModels.outerHTML = response
        },
        complete: function () {
          if (self.hasPopularModelListTarget) {
            favorite_action(self.popularModelListTarget)
            const car_ids = JSON.parse(self.popularCarsTarget.dataset.carIds)
            self.carIdsValue = self.carIdsValue.concat(car_ids)
            self.handleSendButton(self.popularModelListTarget)
          }
        }
      })
    }
  }

  handleSendButton(wrapperTarget) {
    const self = this
    const buttons = wrapperTarget.querySelectorAll('.vehicle__list-area .vehicle__car-item .btn-send')
    buttons.forEach(button => {
      button.addEventListener('click', function(e) {
        e.stopPropagation();

        const offerInfo = {
          item_id: $(this).attr("item-id"),
          seller_id: $(this).attr('seller-id'),
          ...offerCarDetailParams(self.estPriceFormData())
        }

        isUserLogin().then(isUserSignedIn => {
          if (isUserSignedIn) {
            $("#modal-contact-seller").data('offer-info', JSON.stringify(offerInfo))
            $("#modal-contact-seller .seller-name").text($(this).attr("dealer-name"));
            $("#modal-contact-seller").modal();
          } else {
            $('#modal-signin, #modal-signup').data('offer-info', JSON.stringify(offerInfo))
            $("#modal-signin").modal();
          }
        })
      })
    })
  }

  getFavoriteCars(carIDs) {
    const data = { car_ids: JSON.stringify(carIDs) }

    ajax({
      url: '/ajax_v2/favorite_cars',
      data: getDataURLSearchParams(data),
      type: 'GET',
      dataType: 'jsonp',
      success: function (response) {
        const favoriteCarIds = response.favorite_car_ids
        const favoriteButtons = document.querySelectorAll('.sub-info__btn-wrap button.btn-add-fav')

        favoriteButtons.forEach(button => {
          if (favoriteCarIds.includes(Number(button.dataset.carId))) {
            button.classList.add('inactive')
          } else {
            button.classList.remove('inactive')
          }
        })
      }
    })
  }

  loadAnalysisChart(){
    const analysisChart = new AnalysisChart()
    analysisChart.fetchExportAchievementRateChart()
    analysisChart.fetchNumberOfListingsChart()
  }
}
